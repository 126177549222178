<template>
  <div class="titleBar">
    <span />
    {{ title }}
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "基础信息"
    }
  }
};
</script>

<style lang="less" scoped>
.titleBar {
  display: flex;
  align-items: center;
  color: #1f212b;
  padding: 0 0 15px 0;
  margin-left: 20px;
  border-bottom: 1px solid #dbdbdb;
  span {
    margin-right: 10px;
    width: 4px;
    height: 20px;
    border-radius: 2px;
    background: #00a1e9;
  }
}
</style>
