<template>
  <div class="caseDataInfo" v-if="isShow">
    <div class="infoOne">
      <el-form
        :model="caseDataInfo"
        :rules="rulesCaseDataInfo"
        ref="rulesCaseDataInfo"
        label-width="160px"
      >
        <TitleBar title="团队详细信息" style="margin-bottom: 20px" />
        <el-form-item label="团体名称：" prop="teamName">
          <el-input v-model="caseDataInfo.teamName" class="lager-input" />
        </el-form-item>
        <el-form-item label="登记证号：" prop="registrationCode">
          <el-input
            v-model="caseDataInfo.registrationCode"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="发证机关：" prop="issuingAuthority">
          <el-input
            v-model="caseDataInfo.issuingAuthority"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="业务范围：" prop="businessScope">
          <el-input v-model="caseDataInfo.businessScope" class="lager-input" />
        </el-form-item>
        <el-form-item label="法定代表人/负责人：" prop="legalRepresentative">
          <el-input
            v-model="caseDataInfo.legalRepresentative"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="依托单位名称：" prop="supportingUnit">
          <el-input v-model="caseDataInfo.supportingUnit" class="lager-input" />
        </el-form-item>
        <el-form-item label="通讯地址：" prop="address">
          <el-input v-model="caseDataInfo.address" class="lager-input" />
        </el-form-item>
        <el-form-item label="邮编：" prop="postCode">
          <el-input v-model="caseDataInfo.postCode" class="lager-input" />
        </el-form-item>
        <el-form-item label="领域标签：" v-if="personType">
          <el-tag
            v-for="(item, index) in this.caseDataInfo.fieldCategory"
            :key="index"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="技术标签：" v-if="personType">
          <el-tag
            v-for="(item, index) in this.caseDataInfo.techCategory"
            :key="index"
            >{{ item }}</el-tag
          >
        </el-form-item>
        <el-form-item label="行业类别：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.industryCategoryList"
            tag="行业"
            @updateCode="updateCode"
          />
        </el-form-item>
        <el-form-item label="国际标准分类：">
          <dic-checkbox-group
            :code.sync="caseDataInfo.nationalStandardClassificationList"
            tag="国际标准知识"
            @updateCode="updateCode"
          />
        </el-form-item>
        <TitleBar title="标准详细信息" style="margin-bottom: 20px" />
        <el-form-item label="标准名称：" prop="standardName">
          <el-input v-model="caseDataInfo.standardName" class="lager-input" />
        </el-form-item>
        <el-form-item label="标准状态：">
          <dic-radio-group
            :code.sync="caseDataInfo.standardStatus"
            type-code="042"
          />
        </el-form-item>
        <!-- <el-form-item label="标准简介：">
          <el-input
            v-model="caseDataInfo.standardIntro"
            type="textarea"
            style="width:80%"
            :autosize="{ minRows: 4 }"
          />
        </el-form-item> -->
        <el-form-item label="标准编号：" prop="standardId">
          <el-input v-model="caseDataInfo.standardId" class="lager-input" />
        </el-form-item>
        <el-form-item
          label="中国分类标准号："
          prop="chinaStandardClassification"
        >
          <el-input
            v-model="caseDataInfo.chinaStandardClassification"
            class="lager-input"
          />
        </el-form-item>
        <!-- <el-form-item
          label="国际分类标准号："
          prop="nationalStandardClassification"
        >
          <el-input
            v-model="caseDataInfo.nationalStandardClassification"
            class="lager-input"
          />
        </el-form-item> -->
        <el-form-item label="发布日期：">
          <data-time
            @dateChange="dateClick"
            :parentYear="Number(caseDataInfo.publishDateYear)"
            :parentMonth="Number(caseDataInfo.publishDateMonth)"
            :parentDay="Number(caseDataInfo.publishDateDay)"
          />
        </el-form-item>
        <el-form-item label="实施日期：">
          <data-time
            @dateChange="dateClickOne"
            :parentYear="Number(caseDataInfo.implementationDateYear)"
            :parentMonth="Number(caseDataInfo.implementationDateMonth)"
            :parentDay="Number(caseDataInfo.implementationDateDay)"
          />
        </el-form-item>
        <!-- <el-form-item label="制修订：" prop="revise">
          <el-input v-model="caseDataInfo.revise" class="lager-input" />
        </el-form-item>
        <el-form-item label="技术归口：" prop="technicalFocus">
          <el-input v-model="caseDataInfo.technicalFocus" class="lager-input" />
        </el-form-item>
        <el-form-item label="批准发布部门：" prop="approvalReleaseDepartment">
          <el-input
            v-model="caseDataInfo.approvalReleaseDepartment"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="提出部门：" prop="alternativeStandard">
          <el-input
            v-model="caseDataInfo.proposingDepartment"
            class="lager-input"
          />
        </el-form-item>
        <el-form-item label="标准类别：" prop="standardClassification">
          <el-input
            v-model="caseDataInfo.standardClassification"
            class="lager-input"
          />
        </el-form-item> -->
        <!-- <el-form-item label="部委：">
          <dic-radio-group
            :code.sync="caseDataInfo.ministries"
            type-code="043"
          />
        </el-form-item> -->

        <!-- <el-form-item label="行业领域：">
          <dic-radio-group
            :code.sync="caseDataInfo.internationalStandardClassification"
            type-code="044"
          />
        </el-form-item> -->
        <el-form-item label="起草单位：" prop="title" style="width: 600px">
          <el-input v-model="caseDataInfo.draftingUnit" type="textarea" />
        </el-form-item>
        <el-form-item label="起草人：">
          <el-tag
            :key="tag"
            v-for="tag in caseDataInfo.drafterList"
            closable
            :disable-transitions="false"
            @close="handleClose(tag)"
          >
            {{ tag }}
          </el-tag>
          <el-input
            class="input-new-tag"
            v-if="inputVisible"
            v-model="caseDataInfo.value"
            ref="saveTagInput"
            size="small"
            @keyup.enter.native="handleInputConfirm"
            @blur="handleInputConfirm"
          >
          </el-input>
          <el-button
            v-else
            class="button-new-tag"
            size="small"
            @click="showInput"
            >+ New Tag</el-button
          >
        </el-form-item>
        <el-form-item label="上传附件：">
          <div class="policyData">
            <el-input v-model="caseDataInfo.fileNames" class="lager-input">
              <el-button
                slot="append"
                icon="el-icon-download"
                @click="downloadFile"
                v-if="caseDataInfo.fileNames"
              ></el-button>
            </el-input>
            <upload-file
              @upFileKey="fileKey"
              @fileSucee="successUpload"
              style="margin-left: 4px"
            />
          </div>
        </el-form-item>
        <el-form-item label="范围：" prop="title" style="width: 600px">
          <el-input v-model="caseDataInfo.scope" type="textarea" />
        </el-form-item>
        <el-form-item label="主要技术内容：" prop="title" style="width: 600px">
          <el-input v-model="caseDataInfo.technicalContent" type="textarea" />
        </el-form-item>
        <el-form-item
          label="是否包含专利信息："
          prop="title"
          style="width: 600px"
        >
          <el-radio-group v-model="caseDataInfo.isPatentInfo">
            <el-radio label="是"></el-radio>
            <el-radio label="否"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="ess_people" v-if="personType">
        <div class="ess_people_list">创建人：{{ caseDataInfo.createName }}</div>
        <div class="ess_people_list">修改人：{{ caseDataInfo.updateName }}</div>
        <div class="ess_people_list">
          创建时间：{{ caseDataInfo.createTime }}
        </div>
        <div class="ess_people_list">
          修改时间：{{ caseDataInfo.updateTime }}
        </div>
      </div>
    </div>
    <div class="bottom">
      <el-button type="primary" @click="saveItem">保存</el-button>
      <el-button @click="cancelItem">取消</el-button>
    </div>
  </div>
</template>

<script>
import UpLoadImage from "../../Product/UpLoadImage";
import uploadFile from "../../PolicyData/uploadFile";
import DicRadioGroup from "@/components/DicRadioGroup.vue";
import {
  getTeamStandardOne,
  insertTeamStandard,
  updateTeamStandard,
} from "@/api/standardKnowledge";
import DataTime from "@/components/DataTime.vue";
import CascaderArea from "@/components/CascaderArea.vue";
import DicCheckboxGroup from "@/components/DicCheckboxGroup.vue";
import TitleBar from "@/components/TitleBar.vue";
import axios from "axios";
import { URL } from "../../../../config";
export default {
  name: "CaseDataInfo",

  components: {
    DataTime,
    DicCheckboxGroup,
    DicRadioGroup,
    UpLoadImage,
    uploadFile,
    CascaderArea,
    TitleBar,
  },

  watch: {
    "caseDataInfo.id": {
      handler(newVal) {
        if (this.caseDataInfo && newVal) {
          this.isShow = true;
        }
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      inputVisible: false,
      isShow: false,
      optionsAssociation: [],
      caseDataInfo: {
        standardName: "", //标准名称
        // standardIntro: "", //标准简介
        standardId: "", //标准号
        chinaStandardClassification: "", //中国标准分类号
        nationalStandardClassification: "", //国际标准分类号
        publishDate: "", //发布日期
        implementationDate: "", //实施日期
        revise: "", //制修订
        technicalFocus: "", //技术归口
        approvalReleaseDepartment: "", //批准发布部门
        alternativeStandard: "", //代替标准
        standardStatus: "", //标准状态
        industryCategory: [], //行业类别
        industryCategoryChild: [], //行业类别子类别
        standardClassification: "", //标准分类
        ministries: "", //部委
        internationalStandardClassification: "", //行业领域
        draftingUnit: "", //起草单位
        drafterList: [], //起草人
        fileName: "", //上传文件名
        fileNames: "",
        proposingDepartment: "", //提出部门
        teamName: "", //团体名称
        registrationCode: "", //登记证号
        issuingAuthority: "", //发证机关
        businessScope: "", //业务范围
        legalRepresentative: "", //法定负责人
        supportingUnit: "", //依托单位
        address: "", //通讯地址
        postCode: "", //邮编
        nationalStandardClassificationList: [], //国际标准分类
        nationalStandardClassificationListChild: [], //国际标准分类子类别
        scope: "", //范围
        technicalContent: "", //主要技术内容
        isPatentInfo: "", //包含专利信息
      },
      inputValue: "",
      imageList: [],
      options: [],
      options1: [],
      rulesCaseDataInfo: {
        standardName: [
          { required: true, message: "请输入标准名称", trigger: "blur" },
        ],
        standardId: [
          { required: true, message: "请输入标准号", trigger: "blur" },
        ],
      },
      loading: false,
    };
  },

  computed: {
    personType() {
      return Number(this.$route.query.personType);
    },
    policyId() {
      return Number(this.$route.query.id);
    },
    editInfo() {
      return this.$route.params.editInfo;
    },
    Authorization() {
      return sessionStorage.getItem("token");
    },
  },

  created() {
    if (this.personType) {
      this.search();
    }
    if (!this.personType) {
      this.isShow = true;
    }
  },

  methods: {
    async search() {
      const data = {
        id: this.policyId,
      };
      const res = await getTeamStandardOne(data);
      if (res.code == 200) {
        this.caseDataInfo = res.data;
        if (this.caseDataInfo.industryCategory) {
          this.caseDataInfo.industryCategoryList =
            this.caseDataInfo.industryCategory.split(",");
        } else {
          this.caseDataInfo.industryCategoryList = [];
        }

        if (this.caseDataInfo.nationalStandardClassification) {
          this.caseDataInfo.nationalStandardClassificationList =
            this.caseDataInfo.nationalStandardClassification.split(",");
        } else {
          this.caseDataInfo.nationalStandardClassificationList = [];
        }

        if (this.caseDataInfo.fileName) {
          this.caseDataInfo.fileNames = this.caseDataInfo.fileName.substring(
            this.caseDataInfo.fileName.lastIndexOf("/") + 1
          );
        }
        if (this.caseDataInfo.drafter) {
          this.caseDataInfo.drafterList = this.caseDataInfo.drafter.split(",");
        } else {
          this.caseDataInfo.drafterList = [];
        }
        if (this.caseDataInfo.fieldCategory) {
          this.caseDataInfo.fieldCategory =
            this.caseDataInfo.fieldCategory.split(",");
        }
        if (this.caseDataInfo.techCategory) {
          this.caseDataInfo.techCategory =
            this.caseDataInfo.techCategory.split(",");
        }
      } else {
        this.$message.error("查询失败");
      }
    },
    formatNull(val) {
      if (val === null) {
        return [];
      } else {
        return val;
      }
    },
    // 将字符串格式化成数组
    formatString(val) {
      if (!val) {
        return [];
      } else if (!val.length) {
        return val;
      } else {
        return val.split(",");
      }
    },
    updateCode(val, tag) {
      switch (tag) {
        case "技术":
          this.caseDataInfo.nationalStandardClassificationListChild = val;
          break;

        case "行业":
          this.caseDataInfo.industryCategoryListChild = val;
          break;

        case "国际标准知识":
          this.caseDataInfo.nationalStandardClassificationListChild = val;
          break;
        default:
          break;
      }
    },
    dateClick(year, month, day) {
      this.caseDataInfo.publishDateYear = year;
      this.caseDataInfo.publishDateMonth = month;
      this.caseDataInfo.publishDateDay = day;
    },
    dateClickOne(year, month, day) {
      this.caseDataInfo.implementationDateYear = year;
      this.caseDataInfo.implementationDateMonth = month;
      this.caseDataInfo.implementationDateDay = day;
    },
    // 保存
    saveItem() {
      this.$refs.rulesCaseDataInfo.validate(async (valid) => {
        if (valid) {
          if (this.caseDataInfo.nationalStandardClassificationListChild) {
            this.caseDataInfo.nationalStandardClassificationList =
              this.caseDataInfo.nationalStandardClassificationList.concat(
                this.caseDataInfo.nationalStandardClassificationListChild
              );
          }
          if (this.caseDataInfo.industryCategoryListChild) {
            this.caseDataInfo.industryCategoryList =
              this.caseDataInfo.industryCategoryList.concat(
                this.caseDataInfo.industryCategoryListChild
              );
          }
          if (this.caseDataInfo.fieldCategory) {
            this.caseDataInfo.fieldCategory =
              this.caseDataInfo.fieldCategory.join(",");
          } else {
            this.caseDataInfo.fieldCategory = "";
          }
          if (this.caseDataInfo.techCategory) {
            this.caseDataInfo.techCategory =
              this.caseDataInfo.techCategory.join(",");
          } else {
            this.caseDataInfo.techCategory = "";
          }
          let res;
          if (this.personType) {
            res = await updateTeamStandard(this.caseDataInfo);
            if (res.code == 200) {
              this.$message.success("编辑成功");
              this.$router.go(-1);
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            res = await insertTeamStandard(this.caseDataInfo);
            if (res.code == 200) {
              this.$message.success("新增成功");
              this.$router.go(-1);
            } else {
              this.$message.error("新增失败");
            }
          }
        }
      });
    },
    // 下载附件
    async downloadFile() {
      // const URL = "http://192.168.0.12:8085/";
      const url = this.caseDataInfo.fileName;
      axios({
        method: "GET",
        url: `${URL}/public/downloadUrlFile`,
        params: { urlStr: url },
        responseType: "blob",
        headers: {
          // 设置请求头
          Authorization: this.Authorization,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], {
            type: `application/msword`,
          });
          let href = window.URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
          const a = document.createElement("a"); //创建a标签
          a.style.display = "none";
          a.href = href; // 指定下载'链接
          console.log(a.href);
          a.download = url.substring(url.lastIndexOf("/") + 1); //指定下载文件名
          a.click(); //触发下载
          URL.revokeObjectURL(a.href); //释放URL对象
          document.body.removeChild(a); //释放标签
        })
        .catch((err) => {
          //this.$message.error('文件下载失败')
        });
    },
    // 取消
    cancelItem() {
      this.$confirm("确认退出吗 ?", "提示")
        .then(() => {
          this.$router.go(-1);
        })
        .catch(() => {});
    },
    searchCountry(val) {
      this.caseDataInfo.country = val;
    },
    searchProvince(val) {
      this.caseDataInfo.province = val;
    },
    searchCity(val) {
      this.caseDataInfo.city = val;
    },
    //文件上传返回的参数
    fileKey(val) {
      this.caseDataInfo.fileName = val;
      this.caseDataInfo.fileNames = this.caseDataInfo.fileName.substring(
        this.caseDataInfo.fileName.lastIndexOf("/") + 1
      );
    },
    //文件上传成功返回的名字
    successUpload(val) {
      console.log(val);
    },
    handleClose(tag) {
      this.caseDataInfo.drafterList.splice(
        this.caseDataInfo.drafterList.indexOf(tag),
        1
      );
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.caseDataInfo.value;
      if (inputValue) {
        this.caseDataInfo.drafterList.push(inputValue);
      }
      this.inputVisible = false;
      this.caseDataInfo.value = "";
    },
  },
  // mounted() {
  //   document.getElementsByClassName("infoOne")[0].style.height =
  //     document.body.clientHeight - 200 + "px";
  // }
};
</script>

<style lang="less" scoped>
.infoOne {
  overflow: auto;
}
.bottom {
  border-top: 1px solid #dbdbdb;
  background: #fff;
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  .el-button {
    margin: 0 20px;
  }
}
.caseDataInfo {
  background-color: #fff;
  padding: 20px 20px 0 20px;
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
/deep/.el-upload--picture-card {
  width: 80px !important;
  height: 80px !important;
  line-height: 80px !important;
}
.policyData {
  display: flex;
  // justify-content: space-between;
}
.ess_people {
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  margin: 20px 30px;
  align-content: center;

  .ess_people_list {
    width: 100%;
    margin: 4px 0;
    color: #7c7f8e;
    font-size: 12px;
  }
}
/deep/ .el-input-group__append,
.el-input-group__prepend {
  background-color: #fff;
  font-size: 20px;
}
</style>
